import React from 'react';

import { Box, Flex } from '../Core';
import Image, { IImage } from '../Image/Image';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';

interface IBlocPresentationProps {
  color: string;
  description: string;
  image: IImage | null;
  title: string;
}

const BlocPresentation: FCWithClassName<IBlocPresentationProps> = (props) => {
  const { color, description, image, title, ...rest } = props;
  return (
    <Flex
      className="relative w-full"
      justify="flex-end"
      {...rest}
      direction={{ base: 'column', md: 'row' }}
    >
      <Box className="relative md:absolute md:top-0 left-0 xl:left-[10%] xxl:left-[20%] w-full md:w-3/5 xl:w-1/2 lg:z-[-1]">
        <Image image={image} className="aspect-w-1 aspect-h-1" />
      </Box>
      <Flex
        className="w-full md:w-[60%] xxl:w-[50%] mt-0 md:mt-[10vw] p-4 md:p-10"
        direction="column"
        style={{ background: color }}
      >
        <Text variant={TEXT_STYLES.H3}>{title}</Text>
        <Text isProse>{description}</Text>
      </Flex>
    </Flex>
  );
};

export default BlocPresentation;

import React, { Fragment } from 'react';

import { tw } from '../../utils/tailwind';
import { Box, Flex } from '../Core';
import Image, { IImage } from '../Image/Image';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';

interface IBlocFeatureProps {
  alignment?: 'left' | 'right';
  color?: string;
  description: string;
  title: string;
  image?: IImage;
  blockType: 'colorBlock' | 'imageWithTitle';
}

const BlocFeature: FCWithClassName<IBlocFeatureProps> = (props) => {
  const {
    alignment = 'left',
    blockType,
    color,
    description,
    image,
    title,
    ...rest
  } = props;
  const colorBlockVariant = (
    <Flex
      className={`min-h-[250px] lg:min-h-[300px] relative w-full mb-[6rem] ${
        alignment === 'left' ? 'self-start' : 'self-end'
      }`}
      direction="column"
      {...rest}
    >
      <Box
        className={`-top-[20px] absolute z-[-1] ${
          alignment === 'left' ? 'left-0-[10%]' : 'right-[10%]'
        }`}
        style={{ background: color }}
      />
      <Text
        variant={TEXT_STYLES.H3}
        className="self-center max-w-[20em] mb-4 px-8 sm:px-12 md:px-24"
      >
        {title}
      </Text>
      <Text
        variant={TEXT_STYLES.P}
        className={tw(alignment === 'left' ? 'self-start' : 'self-end')}
      >
        {description}
      </Text>
    </Flex>
  );
  const imageWithTitleVariant = (
    <Flex
      direction={{
        base: 'column',
        md: alignment === 'left' ? 'row' : 'row-reverse',
      }}
      className="min-h-[250px] lg:min-h-[300px] relative w-full mb-[4rem] gap-4 lg:gap-16"
      align="center"
      {...rest}
    >
      <Box
        className="w-[90%] lg:w-[45%] h-full relative"
        style={{
          background: color,
          boxShadow:
            alignment === 'left' ? `20px 20px ${color}` : `-20px 20px ${color}`,
        }}
      >
        {image ? (
          <Fragment>
            <Image
              image={image ?? null}
              className="z-10 object-cover h-full"
              imageClassName='h-full'
              withOverlay
            />
            <Text
              variant={TEXT_STYLES.H3}
              className="absolute text-center top-[calc(50%-0.5em)] left-0 right-0 !text-6 md:!text-7 text-white z-10 !max-w-[20em] mb-4 px-8 sm:px-12 md:px-24"
            >
              {title}
            </Text>
          </Fragment>
        ) : (
          <Flex
            className={` w-full relative ${title ? 'h-[150px]' : 'pt-[100%]'}`}
            style={{ background: color }}
            justify="center"
          >
            <Text
              variant={TEXT_STYLES.H3}
              className="md:!text-7 lg:!text-7 mt-[-0.5em] !max-w-[20em] text-center"
            >
              {title}
            </Text>
          </Flex>
        )}
      </Box>
      <Text isProse className="w-[90%] lg:w-[45%] self-center inline-block mt-12 md:mt-0">
        {description}
      </Text>
    </Flex>
  );
  return blockType === 'colorBlock' ? colorBlockVariant : imageWithTitleVariant;
};

export default BlocFeature;

export enum PAGE_TEMPLATES {
  BLOG = 'Page Blog',
  CENTRE = 'Page Centre',
  CONTACT = 'Page Contact',
  EVENEMENT = 'Page Evenement',
  HOMEPAGE = 'Page D\'accueil',
  KINEQUICK = 'Page Kinequick',
  PAGE_BUILDER = 'Page Builder',
  PHYSIOTEC = 'Page Physiotec',
  POST = 'Page Article',
  RECHERCHE_CENTRE = 'Page Recherche De Centre',
  SERVICE = 'Page Service',
  SERVICES = 'Page Services',
  THEMATIQUE = 'Page Thematique',
  THEMATIQUES = 'Page Thematiques',
}

export const PAGES_WITH_OWN_TEMPLATE = [
  PAGE_TEMPLATES.BLOG,
  PAGE_TEMPLATES.CENTRE,
  PAGE_TEMPLATES.POST,
  PAGE_TEMPLATES.SERVICE,
  PAGE_TEMPLATES.SERVICES,
  PAGE_TEMPLATES.THEMATIQUE,
];

export const PAGE_BUILDER_BLOC_NAMES = {
  BLOC_FEATURE: 'page_Contenupagebuilder_Blocs_BlocFeature',
  BLOC_PRESENTATION: 'page_Contenupagebuilder_Blocs_BlocPresentation',
  CTAS: 'page_Contenupagebuilder_Blocs_Ctas',
  SECTION: 'page_Contenupagebuilder_Blocs_Section',
  TEXT: 'page_Contenupagebuilder_Blocs_BlocDeTexte',
  TEXT_BLOCKS: 'page_Contenupagebuilder_Blocs_TextBlocks',
} as const;

export type BlockNames =
  typeof PAGE_BUILDER_BLOC_NAMES[keyof typeof PAGE_BUILDER_BLOC_NAMES];

import { graphql, PageProps } from 'gatsby';
import React from 'react';

import CentresSection from '../../components/CentresSection';
import { Box, Stack } from '../../components/Core';
import { Flex } from '../../components/Core/Flex';
import Hero from '../../components/Hero';
import { HERO_TYPE } from '../../components/Hero/constants';
import Layout from '../../components/Layout';
import PostsSection from '../../components/PostsSection/PostsSection';
import SectionWithTitle from '../../components/SectionWithTitle';
import SEO from '../../components/SEO';
import FlexibleContent from '../../components/Serializers/FlexibleContent';
import ServiceContactForm from '../../components/ServiceContactForm';
import ServiceTag from '../../components/ServiceTag';
import { BUTTON_STYLES } from '../../constants/ui';
import { IWordpressCTA } from '../../types/wordpress/blocks';
import { createImageWithArtDirection } from '../../utils/serializeImageForImageComponent';
import KinequickForm from '../components/Accueil/KinequickForm';

type IServiceTemplate = PageProps<Queries.ServiceQuery, IPageContext>;

const ServiceTemplate: FCWithClassName<IServiceTemplate> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpService;
  const pageTemplateData =
    'contenuService' in data.wpPage.template
      ? data.wpPage.template.contenuService
      : null;
  const service = pageData;
  const posts = data.allWpPost.nodes;
  const otherServices = data.allWpService.nodes;
  if (!pageData || !pageContext.defaultLanguage || !pageTemplateData)
    return null;
  const defaultCentreForContactForm =
    typeof location !== 'undefined'
      ? new URLSearchParams(location.search).get('centre')
      : null;
  const centresOfferingThisService = data.allWpCentre.nodes;
  const contenuSectionCentres = {
    centres: centresOfferingThisService,
    description: pageTemplateData?.centresSection?.description ?? '',
    image: pageTemplateData?.centresSection?.image,
    searchFormLabels:
      'formulaireRechercheCentre' in data.wpPage.template
        ? data.wpPage?.template.formulaireRechercheCentre
            .formulaireRechercheDeCentre
        : null,
    titre: pageTemplateData?.centresSection?.titre,
  };
  const serializedBlocks = (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore we have to override the type of fieldGroupName
    <FlexibleContent blocks={service.informationsService.blocs} />
  );
  const heroImageWithArtDirection = createImageWithArtDirection({
    desktopImage: service.featuredImage?.node,
    mobileImage: service.hero.imageMobile,
  });
  const contenuSectionPosts = pageTemplateData?.articlesSection;
  const isKinequickCompatible = Boolean(
    service.informationsService.kinequick?.speciality
  );
  const heroCta: IWordpressCTA | undefined = pageTemplateData?.headerSection
    ?.ctaLabel
    ? {
        anchor: isKinequickCompatible ? null : '#appointment',
        internalLink: isKinequickCompatible
          ? pageTemplateData?.headerSection?.lien
          : undefined,
        navigationProps: isKinequickCompatible
          ? {
              kinequickSpeciality:
                service.informationsService.kinequick?.speciality ??
                service.title,
              kinequickType: service.informationsService.kinequick?.type,
              services: [service.slug],
            }
          : null,
        style: BUTTON_STYLES.SOLID,
        titre: pageTemplateData?.headerSection?.ctaLabel,
      }
    : undefined;
  const serviceFrenchSlug =
    data.wpService.language.slug === 'fr'
      ? data.wpService.slug
      : data.wpService.translations[0]?.slug;

  const frontpageData =
    'contenuHomepage' in data.frontpage.template
      ? data.frontpage.template.contenuHomepage
      : null;

  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Stack justify="center" className="space-y-gap">
        <Hero
          type={HERO_TYPE.STATIC}
          couleurTitre={service.hero.couleurTitre}
          heading={service.hero.titre}
          tagline={service.hero.tagline}
          backgroundImage={heroImageWithArtDirection}
          kinequickForm={
            frontpageData?.ctaCarroussel && (
              <KinequickForm
                data={
                  data?.configurations?.nodes[0]?.configuration?.linksForRdv
                }
                cta={frontpageData?.ctaCarroussel[0] || undefined}
                servicePlaceholder="Services"
                centrePlaceholder="Centres"
                defaultService={{
                  slug: service.slug,
                }}
                lang={pageContext.currentLanguage.code}
                hideService
              />
            )
          }
        />
        <Box className="-mt-16">{serializedBlocks}</Box>
        <CentresSection
          centres={centresOfferingThisService}
          sectionData={contenuSectionCentres}
          selectedServices={[service.slug]}
        />
        {!isKinequickCompatible ? (
          <SectionWithTitle
            id="appointment"
            heading={pageTemplateData?.serviceRdvSection?.titre}
          >
            <ServiceContactForm
              languageCode={pageContext.currentLanguage.code as 'EN' | 'FR'}
              centres={centresOfferingThisService}
              defaultCentre={defaultCentreForContactForm}
              serviceTitle={service.hero?.titre}
              serviceFrenchSlug={serviceFrenchSlug}
            />
          </SectionWithTitle>
        ) : null}
        <SectionWithTitle
          heading={pageTemplateData?.servicesSection?.titre}
          text={pageTemplateData?.servicesSection?.description}
        >
          <Flex
            className="flex-wrap w-full max-w-[850px] mx-auto gap-2"
            justify="center"
          >
            {otherServices?.map((otherService) => {
              if (!otherService) return undefined;
              return (
                <ServiceTag
                  isMasonry
                  key={otherService.id}
                  uri={otherService.uri}
                  color={otherService.informationsService.couleur}
                  title={otherService.title}
                />
              );
            })}
          </Flex>
        </SectionWithTitle>
        {posts && (
          <PostsSection
            currentLanguageCode={pageContext.currentLanguage.code}
            title={contenuSectionPosts.titre}
            description={contenuSectionPosts.description}
            posts={posts ?? [null]}
            noPostsMessage={contenuSectionPosts?.messagePasArticles}
          />
        )}
      </Stack>
    </Layout>
  );
};

export default ServiceTemplate;

export const query = graphql`
  query Service(
    $id: String
    $currentLanguageSlug: String
    $centresIds: [String]
    $postIds: [String]
  ) {
    wpService(id: { eq: $id }) {
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
        slug
      }
      hero {
        couleurTitre
        tagline
        titre
        imageMobile {
          ...MediaItemFragment
          localFile {
            ...MobileHeroImage
          }
        }
      }
      informationsService {
        description
        shortDescription
        couleur
        kinequick {
          type
          speciality
        }
        blocs {
          ... on WpService_Informationsservice_Blocs_Pictos {
            fieldGroupName
            pictos {
              fieldGroupName
              titre
              icone {
                ...MediaItemFragment
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 600
                      height: 600

                      layout: CONSTRAINED
                    )
                  }
                  publicURL
                }
              }
            }
          }
          ... on WpService_Informationsservice_Blocs_BlocFeature {
            fieldGroupName
            couleur
            description
            titre
            image {
              ...MediaItemFragment
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, height: 300)
                }
                publicURL
              }
            }
            alignement
          }
          ... on WpService_Informationsservice_Blocs_BlocPresentation {
            fieldGroupName
            couleur
            description
            titre
            photo {
              ...MediaItemFragment
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
                publicURL
              }
            }
          }
          ... on WpService_Informationsservice_Blocs_Ctas {
            orientation
            fieldGroupName
            buttons {
              fieldGroupName
              style
              titre
              link {
                target
                title
                url
              }
            }
          }
          ... on WpService_Informationsservice_Blocs_Section {
            description
            fieldGroupName
            titre
            largeur
            espacement
          }
        }
      }
    }
    wpPage(
      language: { slug: { eq: $currentLanguageSlug } }
      template: { templateName: { eq: "Page Service" } }
    ) {
      template {
        ... on WpTemplate_PageService {
          contenuService {
            centresSection {
              titre
              description
              image {
                ...MediaItemFragment
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  publicURL
                }
              }
            }
            headerSection {
              ctaLabel
              lien {
                ... on WpPage {
                  id
                  uri
                }
              }
            }
            serviceRdvSection {
              titre
            }
            servicesSection {
              titre
              description
            }
            articlesSection {
              titre
              description
              messagePasArticles
            }
          }
          formulaireRechercheCentre {
            formulaireRechercheDeCentre {
              adresse
              centreLePlusProche
              geolocalisation
              service
              tousServices
              messageErreurGeolocalisation
              pageDeResultats {
                ... on WpPage {
                  uri
                }
              }
            }
          }
        }
      }
    }
    allWpCentre(filter: { id: { in: $centresIds } }) {
      nodes {
        title
        id
        uri
        coordonnees {
          adresse
          telephone
          nomLong
          lienRdv
        }
        servicesEtStaff {
          staff {
            ... on WpStaff {
              id
            }
          }
        }
        slug
      }
    }
    allWpPost(
      filter: {
        id: { in: $postIds }
        categories: { nodes: { elemMatch: { slug: { ne: "pathologies" } } } }
      }
      sort: { fields: date, order: DESC }
      limit: 4
    ) {
      nodes {
        ...PostEntryFragment
      }
    }
    allWpService(
      filter: {
        id: { ne: $id }
        language: { slug: { eq: $currentLanguageSlug } }
      }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        uri
        title
        informationsService {
          couleur
        }
      }
    }
    frontpage: wpPage(
      isFrontPage: { eq: true }
      language: { slug: { eq: "fr" } }
    ) {
      template {
        ... on WpTemplate_PageDaccueil {
          contenuHomepage {
            ctaCarroussel {
              ... on WpTemplate_PageDaccueil_Contenuhomepage_CtaCarroussel_Cta {
                anchor
                externalLink
                internalLink {
                  ... on WpPage {
                    id
                    uri
                  }
                }
                style
                titre
              }
            }
          }
        }
      }
    }
    configurations: allWpConfiguration(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        configuration {
          linksForRdv {
            target
            url
            service {
              ... on WpService {
                title
                slug
              }
            }
            place {
              ... on WpCentre {
                title
                slug
              }
            }
          }
        }
      }
    }
  }
`;

import './style.css';

import React, { FC } from 'react';

import { tw } from '../../utils/tailwind';
import { Flex } from '../Core';
import StyledLink from '../StyledLink';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';

interface IServiceTag {
  uri: string;
  color: string;
  title: string;
  isMasonry?: boolean;
}

const ServiceTag: FC<IServiceTag> = ({
  isMasonry = false,
  uri,
  color,
  title,
}) => {
  return (
    <StyledLink
      to={uri}
      className={isMasonry ? 'flex flex-auto min-w-fit' : ''}
    >
      <Flex
        className={tw(
          'px-6 py-4 cursor-pointer service-tag bg-primaryMain w-fit',
          isMasonry ? '!w-full text-center justify-center' : null
        )}
        style={{
          '--thematique-hover-color': color,
        } as React.CSSProperties}
      >
        <Text variant={TEXT_STYLES.H4}>{title}</Text>
      </Flex>
    </StyledLink>
  );
};

export default ServiceTag;

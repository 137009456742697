/**
 * Format options for select input from "service" or "place" configurations
 *
 * @param {*} nodes
 * @param {('service' | 'place')} type
 * @return {*}
 */
export const formatSelectOptions = (nodes: any, type: 'service' | 'place') => {
  const options: { slug: string; title: string }[] = [];

  nodes?.forEach((node: any) => {
    //if node type not null
    if (!options.find((centre: any) => centre.title === node?.[type]?.title) && node?.[type]?.title) {
         if (node?.[type]?.title){
            options.push({
                slug: node?.[type]?.slug,
                title: node?.[type]?.title,
            });
        }

    }
  });

  // Tri des options en s'assurant que 'title' existe et est une chaîne
  return options.sort((a, b) => {
    if (typeof a.title === 'string' && typeof b.title === 'string') {
      return a.title.localeCompare(b.title);
    }
    return 0;
  });
};


/**
 * Sync array with filter by string
 *
 * @params {string} - string to filter
 * @params {string} - type of to filter
 *
 * @return {Array} - array filtered
 */
export const syncWith = (array: any[], value: string, key: string) => {
  if (!value) return array;

  array = array.filter((item) => {
    return item[key]?.slug === value;
  });

  return array;
};

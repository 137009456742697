import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { BlockNames, PAGE_BUILDER_BLOC_NAMES } from '../../constants/pages';
import { IWordpressSectionBlock, WordpressFlexibleBlocks } from '../../types/wordpress/blocks';
import { getCorrectLink } from '../../utils/links';
import { serializeImageForImageComponent } from '../../utils/serializeImageForImageComponent';
import BlocFeature from '../BlocFeature/BlocFeature';
import BlocPresentation from '../BlocPresentation';
import { Box, Flex, Stack } from '../Core';
import SectionWithTitle from '../SectionWithTitle';
import StyledLink from '../StyledLink';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';
import ThemedButton from '../ThemedButton';

type Acc = {
  blocks: WordpressFlexibleBlocks[];
  lastSectionIndex?: number;
};

interface IFlexibleContentProps {
  blocks: readonly WordpressFlexibleBlocks[];
}
const FlexibleContent: FCWithClassName<IFlexibleContentProps> = (props) => {
  const standardizedNameBlocks = props.blocks.map((block) => {
    const blockName =
      block.fieldGroupName.split('_')[
        block.fieldGroupName.split('_').length - 1
      ];
    const standardizedBlockName =
      `page_Contenupagebuilder_Blocs_${blockName}` as BlockNames;
    block.fieldGroupName = standardizedBlockName;
    return block;
  });
  const flexibleBlocksBySection = standardizedNameBlocks.reduce(
    (acc: Acc, block) => {
      if (block.fieldGroupName === PAGE_BUILDER_BLOC_NAMES.SECTION) {
        block.content = [];
        acc.blocks = [...acc.blocks, block];
        acc.lastSectionIndex = acc.blocks.length - 1;
      } else {
        if (acc.lastSectionIndex !== undefined) {
          const lastSectionBlock = acc.blocks[acc.lastSectionIndex] as IWordpressSectionBlock;
          lastSectionBlock.content = lastSectionBlock.content && [
            ...lastSectionBlock.content,
            block,
          ];
        } else {
          acc.blocks = [...acc.blocks, block];
        }
      }
      return acc;
    },
    { blocks: [], lastSectionIndex: undefined }
  );
  const serializeBlocks = (block: WordpressFlexibleBlocks): React.ReactNode => {
    switch (block.fieldGroupName) {
      case PAGE_BUILDER_BLOC_NAMES.BLOC_FEATURE:
        return (
          <BlocFeature
            key={uuidv4()}
            color={block.couleur}
            title={block.titre}
            description={block.description}
            alignment={block.alignement}
            blockType={block.blockType}
            image={
              serializeImageForImageComponent({ image: block.image }) ??
              undefined
            }
          />
        );
      case PAGE_BUILDER_BLOC_NAMES.BLOC_PRESENTATION:
        return (
          <BlocPresentation
            key={uuidv4()}
            color={block.couleur}
            title={block.titre}
            description={block.description}
            image={serializeImageForImageComponent({ image: block.photo })}
          />
        );
      case PAGE_BUILDER_BLOC_NAMES.CTAS:
        return (
          <Stack
            key={uuidv4()}
            direction={{ base: 'column', md: block.orientation }}
            justify="space-evenly"
            align="center"
            className="w-full gap-10"
          >
            {block.buttons.map((button) => (
              <StyledLink key={uuidv4()} {...getCorrectLink(button.link.url)}>
                <ThemedButton>{button.titre}</ThemedButton>
              </StyledLink>
            ))}
          </Stack>
        );
      case PAGE_BUILDER_BLOC_NAMES.SECTION: {
        const spacingConvertTailwind = {
          LARGE: 'gap-48',
          MEDIUM: 'gap-24',
          SMALL: 'gap-16',
        };
        const contentWidthToTailwind = {
          large: 'w-full',
          medium: 'w-full lg:w-4/5',
          small: 'w-4/5 sm:w-[70%] md:w-[65%] lg:w-[55%]',
        };
        return (
          <SectionWithTitle
            key={uuidv4()}
            heading={block.titre}
            text={block.description}
            contentAlignment="center"
            style={
              { '--section-bloc-bg': block.couleur } as React.CSSProperties
            }
            className="relative z-0 section-bloc after:text-6 md:after:text-7"
          >
            <Stack
              className={`${spacingConvertTailwind.MEDIUM} ${
                contentWidthToTailwind[block.largeur]
              }
            `}
              align="stretch"
            >
              {block.content?.map(serializeBlocks)}
            </Stack>
          </SectionWithTitle>
        );
      }
      case PAGE_BUILDER_BLOC_NAMES.TEXT:
        return <Text key={uuidv4()} isProse>{block.texte}</Text>;
      case PAGE_BUILDER_BLOC_NAMES.TEXT_BLOCKS:
        return (
          <Flex
            key={uuidv4()}
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
            align={{ base: 'center', md: 'flex-start' }}
            className="flex-wrap w-full gap-8 px-6"
          >
            {block.blocs.map((textBlock) => {
              const maxWidthConvertTailwind = {
                large: 'lg:max-w-[45%]',
                medium: 'lg:max-w-[30%]',
                small: 'lg:max-w-[22%]',
              };
              return (
                <Box
                  key={uuidv4()}
                  className={`min-w-[190px] ${
                    maxWidthConvertTailwind[textBlock.largeurMaxDuBloc] ??
                    maxWidthConvertTailwind.medium
                  }`}
                >
                  {textBlock.titre && (
                    <Flex className="h-16 mb-4" align="center" justify="center">
                      <Text variant={TEXT_STYLES.H4} className="text-center">
                        {textBlock.titre}
                      </Text>
                    </Flex>
                  )}
                  {textBlock.texte && <Text isProse>{textBlock.texte}</Text>}
                </Box>
              );
            })}
          </Flex>
        );
      default:
        return null;
    }
  };
  const serializedBlocks = flexibleBlocksBySection.blocks.map(serializeBlocks);
  return (
    <Stack className=" space-y-gap lg:space-y-gap-xl">{serializedBlocks}</Stack>
  );
};

export default FlexibleContent;

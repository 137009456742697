import en_GB from '../../locales/en_GB.json';

type Langs = {
  [key: string]: {
    [key: string]: string;
  };
};

const langs: Langs = {
  EN: en_GB,
};

export const __ = (key: string, lang = 'FR'): string => {
  lang = lang.toUpperCase();

  if (!langs[lang]) {
    return key;
  }
  return langs[lang][key] || key;
};

/**
 * This function is used to translate the key and avoid use of HTML tags in the translation
 *
 * @param key - The key to translate
 * @param lang - The language to translate to
 *
 * @returns The translated key
 */

export const __html = (key: string, lang = 'FR'): string => {
  if (!langs[lang]) {
    return key;
  }
  return langs[lang][key] || key;
};

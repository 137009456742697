import { MdErrorOutline } from '@react-icons/all-files/md/MdErrorOutline';
import { TiTick } from '@react-icons/all-files/ti/TiTick';
import React, { useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useForm } from 'react-hook-form';

import { tw } from '../../utils/tailwind';
import { Grid } from '../Core';
import { Flex } from '../Core/Flex';
import FormField from '../Form';
import { IQuestion } from '../Newsletter/Newsletter';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';
import ThemedButton from '../ThemedButton';

interface IServiceContactFormProps {
  centres: Queries.ServiceQuery['allWpCentre']['nodes'];
  defaultCentre?: string;
  languageCode: 'EN' | 'FR';
  serviceTitle: string;
  serviceFrenchSlug: string;
}

export interface ServiceContactFormData {
  [key: string]: string | number | undefined;
}

const STATIC_STRINGS = {
  EN: {
    button: 'Ask for an appointment',
    feedback: {
      error: 'Sorry, something went wrong, please try again later.',
      success: 'Thank you! We will come back to you soon.',
    },
    fields: {
      centre: 'Choose a centre',
      email: 'Your email',
      firstName: 'Your first name',
      message: 'Your need',
      name: 'Your last name',
      phone: 'Your phone',
    },
  },
  FR: {
    button: 'Demander un rendez vous',
    feedback: {
      error: "Désolé, une erreur s'est produite. Merci de réessayer plus tard.",
      success: 'Votre demande a bien été envoyée.',
    },
    fields: {
      centre: 'Choisir un centre',
      email: 'Votre email',
      firstName: 'Votre prénom',
      message: 'Votre besoin',
      name: 'Votre nom',
      phone: 'Votre téléphone',
    },
  },
};

const ServiceContactForm: FCWithClassName<IServiceContactFormProps> = (
  props
) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ServiceContactFormData>();

  const onSubmit = (values: ServiceContactFormData) => {
    setShowError(false);
    const encode = (formDataToEncode: ServiceContactFormData) => {
      const message = Object.keys(formDataToEncode)
        .map((key) => {
          if (key === 'alternative-email') return null;
          const displayedKey = key;
          const displayedValue = values[key];
          const label = `${displayedKey} :`;
          return renderToStaticMarkup(
            <p>
              <strong>{label}</strong> {displayedValue}
            </p>
          );
        })
        .join('');
      const centreName = props.centres?.find(
        (centre) => centre.slug === values.centre
      )?.coordonnees.nomLong;

      return {
        alternativeEmail: values['alternative-email'], // this is the honeypot field for antispam
        clientCentre: centreName,
        clientEmail: values.email,
        clientFirstName: values.firstName,
        clientLastName: values.lastName,
        clientMessage: values.message,
        clientPhone: values.phone,
        clientService: props.serviceFrenchSlug, // we want a unique slug for french or english page
        message,
        source: 'Formulaire de contact service',
      };
    };
    fetch(process.env.GATSBY_WORDPRESS_URL + '/wp-json/contact/v1/send', {
      body: JSON.stringify(encode(values)),
      headers: { 'Content-Type': 'text/html' },
      method: 'POST',
    })
      .then(() => setShowSuccess(true))
      .catch(() => setShowError(true));
  };

  const displayFeedbackMessage = () => {
    if (showSuccess) {
      return (
        <Flex direction="column" align="center" className="mx-auto mb-16">
          <TiTick className="text-green-500 text-[80px] mb-4" />
          <Text variant={TEXT_STYLES.H4} className="mx-auto text-center">
            {STATIC_STRINGS[props.languageCode].feedback.success}
          </Text>
        </Flex>
      );
    } else if (showError) {
      return (
        <Flex direction="column" align="center" className="mx-auto mb-16">
          <MdErrorOutline className="text-orange-500 text-[80px] mb-4" />
          <Text variant={TEXT_STYLES.H4} className="mx-auto text-center">
            {STATIC_STRINGS[props.languageCode].feedback.error}
          </Text>
        </Flex>
      );
    } else {
      return null;
    }
  };

  const botFieldLabel = 'Alternative email'; // this is a honeypot field
  const questions: IQuestion[] = [
    {
      label: STATIC_STRINGS[props.languageCode].fields.firstName,
      name: 'firstName',
      required: true,
      type: 'text',
    },
    {
      label: STATIC_STRINGS[props.languageCode].fields.name,
      name: 'lastName',
      required: true,
      type: 'text',
    },
    {
      label: STATIC_STRINGS[props.languageCode].fields.email,
      name: 'email',
      required: true,
      type: 'email',
    },
    {
      label: STATIC_STRINGS[props.languageCode].fields.phone,
      name: 'phone',
      required: true,
      type: 'text',
    },
    {
      label: STATIC_STRINGS[props.languageCode].fields.centre,
      name: 'centre',
      options: props.centres.map((centre) => {
        return {
          checked: props.defaultCentre === centre.slug,
          label: centre.title,
          name: centre.slug,
        };
      }),
      required: true,
      type: 'select',
    },
    {
      label: botFieldLabel,
      name: 'alternative-email',
      required: false,
      type: 'text',
    },
    {
      label: STATIC_STRINGS[props.languageCode].fields.message,
      name: 'message',
      required: true,
      rows: 5,
      type: 'textArea',
    },
  ];
  return (
    displayFeedbackMessage() || (
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          margin: '0 auto',
          maxWidth: '850px',
          paddingTop: '24px',
          width: '100%',
        }}
      >
        <Grid className="grid-cols-1 md:grid-cols-2 max-w-[1000px] gap-3 mb-4">
          {questions.map((question) => {
            return (
              <FormField
                autocomplete={
                  question.name === 'alternative-email' ? 'off' : 'on'
                }
                aria-hidden={
                  question.name === 'alternative-email' ? true : false
                }
                value={
                  question?.options?.find((option) => option.checked)?.name ??
                  null
                }
                type={question.type}
                key={question.name}
                name={question.name}
                placeholder={question.label}
                options={question.options}
                register={register}
                errors={errors[question.name]}
                required={question.required}
                rows={question.rows}
                defaultCentre={props.defaultCentre}
                wrapperClassName={tw(
                  ['message'].includes(question.name) ? 'lg:col-span-2' : '',
                  question.name === 'alternative-email' ? 'form--input' : ''
                )}
              />
            );
          })}
        </Grid>
        <ThemedButton
          type="submit"
          isLoading={isSubmitting}
          className="sm:!w-full md:!w-fit"
        >
          {STATIC_STRINGS[props.languageCode].button}
        </ThemedButton>
      </form>
    )
  );
};

export default ServiceContactForm;
